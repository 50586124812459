import { useState, useCallback } from 'react'
import { Button } from "./components/ui/button"
import { Input } from "./components/ui/input"
import { ethers } from 'ethers'
import { Label } from "./components/ui/label"
import GradesLogo from "./images/Grades-Logo.png"
import { RadioGroup, RadioGroupItem } from "./components/ui/radio-group"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./components/ui/table"
import { Loader2, Download, Share2, ChevronDown } from "lucide-react"
import ScrollLogo from "./images/scroll-logo.png"
import { Pagination } from "./components/ui/pagination"

const NFT_ABI = [
  "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
  "function balanceOf(address owner) view returns (uint256)",
  "function ownerOf(uint256 tokenId) view returns (address)"
];

const ERC20_ABI = [
  "function balanceOf(address account) view returns (uint256)",
  "function decimals() view returns (uint8)",
  "function symbol() view returns (string)",
  "event Transfer(address indexed from, address indexed to, uint256 value)"
];

export default function Hero() {
  const [contractType, setContractType] = useState("1")
  const [contractAddress, setContractAddress] = useState("")
  const [isAnalyzing, setIsAnalyzing] = useState(false)
  const [results, setResults] = useState([])
  const [error, setError] = useState(null)
  const [progress, setProgress] = useState(0)
  const [exportMessage, setExportMessage] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalHolders, setTotalHolders] = useState(0);
  const [lastProcessedBlock, setLastProcessedBlock] = useState(0);
  const holdersPerPage = 500;
  const blocksPerChunk = 100000;

  
  const RPC_URLS = [
    'https://rpc.scroll.io',
    'https://scroll-mainnet.public.blastapi.io', // Fallback RPC URL
    'https://scroll.drpc.org',
    'https://scroll-mainnet.chainstacklabs.com'
  ];
  const getProvider = (url) => new ethers.providers.JsonRpcProvider(url);
  
  const provider = new ethers.providers.JsonRpcProvider('https://rpc.scroll.io')

  const handleAnalyze = async () => {
    setIsAnalyzing(true);
    setError(null);
    setProgress(0);
    setLastProcessedBlock(0);
    setResults([]);
  
    for (let providerIndex = 0; providerIndex < RPC_URLS.length; providerIndex++) {
      const provider = getProvider(RPC_URLS[providerIndex]);
      
      while (true) {
        try {
          let result;
          if (contractType === "1") {
            result = await getNFTHolders(contractAddress, setProgress, provider);
          } else if (contractType === "3") {
            result = await getERC20Holders(contractAddress, setProgress, provider);
          }
          
          if (result && result.holders && result.holders.length > 0) {
            setResults(prevResults => [...prevResults, ...result.holders]);
            setTotalHolders(result.holders.length);
            
            if (contractType === "3" && result.hasMore) {
              // Continue processing for ERC20 tokens
              continue;
            }
          }
          
          setIsAnalyzing(false);
          setProgress(100);
          break;
        } catch (error) {
          console.error('Error analyzing contract:', error);
          if (providerIndex === RPC_URLS.length - 1) {
            setError(`Failed to analyze contract. Please try again later. Error: ${error.message}`);
            setIsAnalyzing(false);
            break;
          }
          // Try the next provider
          break;
        }
      }
      
      if (!isAnalyzing) break;
    }
  };


  async function getNFTHolders(contractAddress, updateProgress, provider) {
    try {
      const contract = new ethers.Contract(contractAddress, NFT_ABI, provider);
      console.log("Contract instance created");
      
      const currentBlock = await provider.getBlockNumber();
      const blockIncrement = 100000; // Increased block range per query
      let balances = {};
  
      const processChunk = async (fromBlock, toBlock) => {
        try {
          const filter = contract.filters.Transfer();
          const events = await contract.queryFilter(filter, fromBlock, toBlock);
          events.forEach(event => {
            const from = event.args.from;
            const to = event.args.to;
  
            if (from !== ethers.constants.AddressZero) {
              balances[from] = (balances[from] || 0) - 1;
              if (balances[from] === 0) delete balances[from];
            }
  
            if (to !== ethers.constants.AddressZero) {
              balances[to] = (balances[to] || 0) + 1;
            }
          });
          return events.length;
        } catch (error) {
          console.error(`Error processing chunk ${fromBlock}-${toBlock}:`, error);
          return 0;
        }
      };
  
      const chunks = [];
      const startBlock = Math.max(currentBlock - 1000000, 0); // Analyze last ~1M blocks
      for (let i = startBlock; i <= currentBlock; i += blockIncrement) {
        chunks.push([i, Math.min(i + blockIncrement - 1, currentBlock)]);
      }
  
      const totalChunks = chunks.length;
      let processedEvents = 0;
  
      await Promise.all(chunks.map(async ([from, to], index) => {
        const eventsProcessed = await processChunk(from, to);
        processedEvents += eventsProcessed;
        updateProgress((index + 1) / totalChunks * 100);
      }));
  
      console.log(`Processed ${processedEvents} Transfer events`);
  
      const holders = Object.entries(balances)
        .filter(([_, balance]) => balance > 0)
        .map(([address, balance]) => ({ address, balance: balance.toString() }));
  
      console.log(`Analysis complete. Found ${holders.length} holders.`);
      updateProgress(100);
      return { holders };
    } catch (error) {
      console.error("Error in getNFTHolders:", error);
      throw error;
    }
  }

  const getERC20Holders = useCallback(async (contractAddress, updateProgress, provider) => {
    const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider);
    const symbol = await contract.symbol();
    const decimals = await contract.decimals();
    const currentBlock = await provider.getBlockNumber();
    let holders = {};

    console.log(`Analyzing ERC20 token: ${symbol} (${decimals} decimals)`);

    const processChunk = async (fromBlock, toBlock) => {
      const filter = contract.filters.Transfer();
      const events = await contract.queryFilter(filter, fromBlock, toBlock);
      events.forEach(event => {
        const { from, to, value } = event.args;
        if (from !== ethers.constants.AddressZero) {
          holders[from] = (holders[from] || ethers.BigNumber.from(0)).sub(value);
        }
        if (to !== ethers.constants.AddressZero) {
          holders[to] = (holders[to] || ethers.BigNumber.from(0)).add(value);
        }
      });
      return events.length;
    };

    const startBlock = lastProcessedBlock || 0;
    const endBlock = Math.min(startBlock + blocksPerChunk, currentBlock);

    try {
      const eventsProcessed = await processChunk(startBlock, endBlock);
      console.log(`Processed ${eventsProcessed} events from block ${startBlock} to ${endBlock}`);
      
      const progress = ((endBlock - startBlock) / (currentBlock - startBlock)) * 100;
      updateProgress(progress);

      setLastProcessedBlock(endBlock);

      // Filter out zero balances and sort
      const sortedHolders = Object.entries(holders)
        .filter(([_, balance]) => !balance.isZero())
        .sort((a, b) => b[1].gt(a[1]) ? 1 : -1)
        .map(([address, balance]) => ({
          address,
          balance: ethers.utils.formatUnits(balance, decimals)
        }));

      setTotalHolders(sortedHolders.length);
      return { symbol, holders: sortedHolders, hasMore: endBlock < currentBlock };
    } catch (error) {
      console.error("Error processing chunk:", error);
      throw error;
    }
  }, [lastProcessedBlock]);

  const getPaginatedHolders = () => {
    const startIndex = (currentPage - 1) * holdersPerPage;
    const endIndex = startIndex + holdersPerPage;
    return results.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  async function getDeploymentBlock(contract) {
    const filter = contract.filters.Transfer(null, null, null)
    const events = await contract.queryFilter(filter, 0, 'latest')
    
    if (events.length === 0) {
      throw new Error('No Transfer events found for the contract. Unable to determine the deployment block.')
    }
    
    return events[0].blockNumber
  }

  const exportToCSV = () => {
    if (results.length === 0) {
      setExportMessage('No data to export');
      setTimeout(() => setExportMessage(''), 3000); 
      return;
    }
  
    const csvContent = [
      ['Address', 'Balance'],
      ...results.map(result => [result.address, result.balance])
    ].map(e => e.join(',')).join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'contract_analysis_results.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setExportMessage('Export successful!');
    setTimeout(() => setExportMessage(''), 3000);
  }
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-100 via-pink-100 to-blue-100 flex flex-col bg-cover bg-center bg-no-repeat">
      <header className="bg-white bg-opacity-90 backdrop-blur-md shadow-md">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <img src={GradesLogo} alt="Grades logo" className="w-32" />
          <nav>
            <a href="https://discord.com/invite/pXwxXyKAz2" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900 px-3 py-2 text-sm font-medium transition-colors duration-200 ease-in-out">Contact us</a>
          </nav>
        </div>
      </header>
  
      <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div>                    
          <h1 className="text-4xl font-bold text-gray-900 font-display">Gradeshot</h1>
          <div className="flex items-center">
            <p className="mt-1 text-sm text-gray-600">Take instant Snapshot of any ERC20 or NFT contract on SCROLL L2</p>
            <img src={ScrollLogo} alt="Scroll logo" className="w-4 ml-2" />
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-2xl p-8 backdrop-blur-lg bg-opacity-80 mt-6">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Contract Analysis</h2>
          <form onSubmit={(e) => { e.preventDefault(); handleAnalyze(); }} className="space-y-6">
            <div>
              <Label htmlFor="contract-type" className="text-lg font-medium text-gray-700">Contract Type</Label>
              <RadioGroup id="contract-type" value={contractType} onValueChange={setContractType} className="flex space-x-4 mt-2">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="1" id="nft" />
                  <Label htmlFor="nft" className="cursor-pointer">NFT</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="3" id="erc20" />
                  <Label htmlFor="erc20" className="cursor-pointer">ERC20</Label>
                </div>
              </RadioGroup>
            </div>
  
            <div>
              <Label htmlFor="contract-address" className="text-lg font-medium text-gray-700">Contract Address</Label>
              <Input
                id="contract-address"
                type="text"
                placeholder="Enter contract address"
                value={contractAddress}
                onChange={(e) => setContractAddress(e.target.value)}
                className="mt-1 w-full"
              />
            </div>
  
            <Button type="submit" className="w-full bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-semibold py-3 rounded-lg shadow-md hover:from-purple-600 hover:to-indigo-700 transition-all duration-300 ease-in-out" disabled={isAnalyzing}>
              {isAnalyzing ? (
                <>
                  <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                  Analyzing...
                </>
              ) : (
                <>
                  Take Snapshot
                  <ChevronDown className="ml-2 h-5 w-5" />
                </>
              )}
            </Button>
          </form>
  
          {isAnalyzing && (
            <div className="mt-6">
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
              </div>
              <p className="text-sm text-gray-600 mt-2">Analyzing... {progress.toFixed(2)}% complete</p>
            </div>
          )}
                    
          {results.length > 0 && (
            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Analysis Results</h2>
              <p>Total Holders: {totalHolders}</p>
              {contractType === "3" && results[0].symbol && <p>Token: {results[0].symbol}</p>}
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Address</TableHead>
                    <TableHead>
                      {contractType === "1" ? "NFTs Owned" : "Token Balance"}
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {getPaginatedHolders().map((result, index) => (
                    <TableRow key={index}>
                      <TableCell className="px-4 py-2 text-gray-900 border border-gray-300">{result.address}</TableCell>
                      <TableCell className="px-4 py-2 text-gray-900 border border-gray-300">
                        {contractType === "3" 
                          ? parseFloat(result.balance).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 6
                            })
                          : result.balance
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {totalHolders > holdersPerPage && (
                <div className="mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(totalHolders / holdersPerPage)}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
              <div className="mt-4 flex space-x-4">
                <Button variant="outline" className="bg-black text-white" onClick={exportToCSV} disabled={results.length === 0}>
                  <Download className="mr-2 h-4 w-4" />
                  Export Results
                </Button>
              </div>
              {exportMessage && (
                <div className={`mt-2 text-sm ${exportMessage.includes('successful') ? 'text-green-600' : 'text-red-600'}`}>
                  {exportMessage}
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );

}