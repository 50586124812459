import React from "react"
import DiscordIcon from "./images/discord-icon.svg"
import XIcon from "./images/x-icon.svg"
import GradesLogo from "./images/Grades-Logo.png"

const socialIcons = [
    { src: DiscordIcon, alt: "Discord icon", href: "https://discord.com/invite/pXwxXyKAz2" },
    { src: XIcon, alt: "X icon", href: "https://twitter.com/@GradesNFT" },
  ];

const links = [
  { text: "Gradepaper", href: "https://gradepaper.gradeseco.com", column: 1 },
  { text: "GradePop", href: "https://gradepop.gradeseco.com", column: 1 },
  { text: "Team", href: "/team", column: 1 },
  { text: "Marketplace", href: "https://marketplace.gradeseco.com", column: 2 },
  { text: "Linktree", href: "https://linktr.ee/gradeseco", column: 2 },
  { text: "Staking", href: "https://staking.gradeseco.com", column: 2 }
]

export default function Footer() {
  return (
    <footer className="w-full bg-gradient-to-b from-blue-100 to-white py-12 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <a href="/" className="mb-4 md:mb-0">
            <img
              src={GradesLogo}
              alt="Grades logo"
              className="w-32"
            />
          </a>
          <div className="flex gap-4">
  {socialIcons.map((icon, index) => (
    <a
      key={index}
      href={icon.href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={icon.ariaLabel}
    >
      <img
        src={icon.src}
        alt={icon.alt}
        className="w-7 h-7 cursor-pointer"
      />
    </a>
  ))}
</div>
        </div>
        
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-8">
          {[1, 2].map((column) => (
            <div key={column} className="space-y-2">
              <h3 className="text-lg font-semibold text-gray-700 mb-2">Links</h3>
              {links.filter(link => link.column === column).map((link, index) => (
                <a key={index} href={link.href} className="block text-gray-600 hover:text-gray-900 transition-colors">
                  {link.text}
                </a>
              ))}
            </div>
          ))}
        </div>
        
        <div className="border-t border-gray-200 pt-4 flex flex-col sm:flex-row justify-between items-center">
          <p className="text-sm text-gray-500 mb-2 sm:mb-0">© 2024 Grades. All rights reserved.</p>
          <div className="flex space-x-4">
            <a href="/privacy" className="text-sm text-gray-500 hover:text-gray-700">Privacy Policy</a>
            <a href="/terms" className="text-sm text-gray-500 hover:text-gray-700">Terms of Service</a>
          </div>
        </div>
      </div>
      
      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
        <div className="absolute top-1/4 left-1/4 w-12 h-12 bg-yellow-300 rounded-full opacity-50 animate-float"></div>
        <div className="absolute top-1/3 right-1/4 w-8 h-8 bg-blue-300 rounded-full opacity-50 animate-float animation-delay-1000"></div>
        <div className="absolute bottom-1/4 left-1/3 w-10 h-10 bg-pink-300 rounded-full opacity-50 animate-float animation-delay-2000"></div>
        <div className="absolute top-1/2 right-1/3 text-4xl opacity-30 animate-float animation-delay-3000">+</div>
        <div className="absolute bottom-1/3 right-1/4 text-4xl opacity-30 animate-float">×</div>
      </div>
    </footer>
  )
}