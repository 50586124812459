
import Footer from './Footer'
import Hero from './Hero'

export default function App() {


  return (
   <div>
<Hero/>
   <Footer />
    </div>
  )
}
